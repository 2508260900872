import * as React from "react"
import {Link} from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import {Component} from "react";
import {Helmet} from "react-helmet";

export class ScheduleDemo extends Component {

    componentDidMount() {
        this.initializeHubspotForm();
    }

    initializeHubspotForm() {
        if ('hbspt' in window) {
            window.hbspt.forms.create({
                region: "eu1",
                portalId: "25215065",
                formId: "2268a80a-3024-477b-9475-762b35f56193",
                target: "#formContainer"
            });
        } else {
            setTimeout(this.initializeHubspotForm, 500)
        }
    }


    render() {
        return (
            <Layout>

                <section className={"form"}>
                    <aside>
                        <h1>Request a Quote</h1>
                        <p className={"head"}>Hyperstack, a Credential Cloud platform where you can create Certificates,
                            Badges and Credentials
                            that you can share, manage, verify and deploy in minutes.</p>

                        <p>Hyperstack has affordable plans based on your needs. Whether you want to send 500 credentials
                            or 5,000,000 credentials we got you covered with the best plan.</p>
                    </aside>
                    <aside className={"form__body"}>
                        <div id="formContainer">
                            <p>Form not showing?
                                <span className={"text-blue pointer"} onClick={()=>this.initializeHubspotForm()}>Reload form</span>
                            </p>
                        </div>
                    </aside>
                </section>

            </Layout>
        )
    }
}

export const Head = () => <Seo
    title="Request Quote from Hyperstack"
    description="Hyperstack has affordable plans based on your needs. Whether you want to send 500 credentials or 5 Million credentials we got you covered with the best plan"
/>

export default ScheduleDemo
